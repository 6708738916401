<template>
  <div>
    <Breadcrumbs
      src="https://res.cloudinary.com/changa/image/upload/f_auto,q_auto/v1643117694/web/top-banner-med-communities.jpg"
      breadcrumb="M-Changa Communities"
      :title="communityName + ' Community'"
    />
    <section id="content">
      <div class="section nomargin med-communities-about">
        <div class="container">
          <div class="heading-block center">
            <h2>About</h2>
          </div>
          <div class="row justify-content-center">
            <div class="col-md-10">
              <p class="lead-paragraph text-center">
                This is a community of
                {{ communityName }} fundraisers.<br />Learn more at:
                <a href=" /communities"> Communities</a>
              </p>
            </div>
          </div>
          <div class="row med-com-contact">
            <div class="col-md-6">
              <div class="med-contact-inner">
                <img class="mx-auto" :src="src_p" />
                <div class="contact-descripiton">{{ text_p }}</div>
                <div class="contact-detail">{{ contact_p }}</div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="med-contact-inner">
                <img class="mx-auto" :src="src_e" />
                <div class="contact-descripiton">{{ text_e }}</div>
                <div class="contact-detail">{{ contact_e }}</div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4 col-4">
              <div class="statistic-item center">
                <div class="counter">
                  <number
                    v-if="this.currency == 'KES'"
                    :format="theFormat"
                    :to="raisedAmount"
                    :duration="0.5"
                  ></number>
                  <number
                    v-else
                    :format="theFormat"
                    :to="display_amount"
                    :duration="0.5"
                  ></number>
                </div>
                <p>Funds Raised ({{ this.currency }})</p>
              </div>
            </div>
            <div class="col-md-4 col-4">
              <div class="statistic-item center">
                <div class="counter">
                  <number
                    :format="theFormat"
                    :to="totalDonors"
                    :duration="0.5"
                  ></number>
                </div>
                <p>Donations</p>
              </div>
            </div>
            <div class="col-md-4 col-4">
              <div class="statistic-item center">
                <div class="counter">
                  <number
                    :format="theFormat"
                    :to="totalFundraisers"
                    :duration="0.5"
                  ></number>
                </div>
                <p>Fundraisers</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="section nobg nomargin nopadding medical-stories-section" v-if="communityTransID == 56272">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <img class="imgcenter" src="https://res.cloudinary.com/changa/image/upload/c_thumb,g_face,h_200/v1666958302/web/tadamon-un.png" />
            </div>
          </div>
        </div>
      </div>
      <div class="section nobg nomargin medical-stories-section">
        <div class="container">
          <div class="row">
            <div class="col-md-4" v-for="n in myFundraisers" :key="n">
              <ProductCard
                :fundraiserCurrency="currency"
                :fundraiserAmount="
                  (n.cb_amount / local_ex_rate).toLocaleString('en', {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })
                "
                :fundraiserName="n.campaign_name"
                :fundraiserLink="'/fundraiser/' + n.transaction_id"
                :fundraiserImage="n.profile_image"
              />
            </div>
          </div>
          <div class="row med-com-pagination">
            <div class="col-md-12">
              <!-- <Pagination /> -->
            </div>
          </div>
        </div>
      </div>
      <div class="section nomargin med-communities-comment">
        <div class="container">
          <div class="heading-block center">
            <h2>Well Wisher Comments</h2>
          </div>
          <div class="comment-plugin-wrap">
            <div style="text-align: center; height: 300px; line-height: 300px">
              <Disqus shortname="cngdev" :pageConfig="pageConfig" />
            </div>
          </div>
        </div>
      </div>
      <QuestionBottom />
    </section>
    <Footer />
  </div>
</template>
<script>
import Breadcrumbs from "../components/Breadcrumbs.vue";
import QuestionBottom from "../components/QuestionBottom.vue";
import Pagination from "../components/Pagination.vue";
import ProductCard from "../components/ProductCard.vue";
import Footer from "../components/Footer/index.vue";
import { Disqus } from "vue-disqus";

export default {
  components: {
    Breadcrumbs,
    QuestionBottom,
    Pagination,
    ProductCard,
    Footer,
    Disqus,
  },
  data() {
    return {
      src_p:
        "https://res.cloudinary.com/changa/image/upload/f_auto,q_auto/v1643117694/web/ic-phone-2.png",
      text_p: "Contact community Coordinator :",
      contact_p: "",
      src_e:
        "https://res.cloudinary.com/changa/image/upload/f_auto,q_auto/v1643117694/web/ic-mail-2.png",
      text_e: "To request to join Community :",
      contact_e: this.supportEmail,
      communityName: "",
      raisedAmount: 0,
      totalDonors: 0,
      totalFundraisers: 0,
      campaigns: {},
      myFundraisers: {},
      currency: "KES",
      display_amount: 0,
      local_ex_rate: 1,
      pageConfig: {
        identifier: 0,
        url: window.location.href,
      },
      communityTransID: 0,
    };
  },
  async created() {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ transactionID: this.$route.params.id }),
    };
    const response = await fetch(
      process.env.VUE_APP_ROOT_API + `/service/community/description`,
      requestOptions
    )
      .then(async (response) => {
        const data = await response.json();

        // check for error response
        if (!response.ok) {
          // get error message from body or default to response status
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
        }
        const communityData = data.communityData;
        this.pageConfig.identifier = communityData.comm_camp_id;
        this.pageConfig.url =
          process.env.VUE_APP_BASE_URL +
          "/fundraiser/" +
          communityData.comm_acct_no;
        this.communityName = communityData.community_name;
        this.raisedAmount = data.communityAmount.raisedAmount;
        this.totalDonors = data.communityAmount.donors;
        this.totalFundraisers = communityData.myCount;
        this.contact_p = this.supportPhone; // data.communityCampaign.fundraiser_phone
        this.campaigns = communityData.campaigns;
        this.currency = data.curr_config.currency_code;
        this.communityTransID = communityData.comm_acct_no;
        if (this.currency != "KES") {
          this.display_amount = data.ex_rates.display_amount;
          this.local_ex_rate = data.ex_rates.val;
        }
      })
      .catch((error) => {
        this.errorMessage = error;
        console.error("There was an error!", error);
      });

    // Get Fundraisers in the community
    const requestOptions_f = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ campaigns: this.campaigns }),
    };
    const response_f = await fetch(
      process.env.VUE_APP_ROOT_API + `/service/community/fundraisers`,
      requestOptions_f
    )
      .then(async (response_f) => {
        const data_f = await response_f.json();

        // check for error response
        if (!response_f.ok) {
          // get error message from body or default to response status
          const error = (data_f && data_f.message) || response_f.status;
          return Promise.reject(error);
        }
        const commFundraisers = data_f.sort((a, b) =>
          a.cb_amount < b.cb_amount ? 1 : b.cb_amount < a.cb_amount ? -1 : 0
        );
        this.myFundraisers = commFundraisers;
      })
      .catch((error) => {
        this.errorMessage = error;
        console.error("There was an error!", error);
      });
  },
};
</script>
