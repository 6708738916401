<template>
  <div class="medical-story-item">
    <img :src="fundraiserImage" />
    <div class="medical-raised">Raised : {{fundraiserCurrency}} {{fundraiserAmount}}</div>
    <h4 class="medical-title">{{fundraiserName}}</h4>
    <a :href="fundraiserLink" class="button mx-auto">Read Their Story</a>
  </div>
</template>

<script>

export default{
  props: {
    fundraiserCurrency: {
      default: 'KES',
      type: Text
    },
    fundraiserAmount: {
      default: 0,
      type: Number
    },
    fundraiserName: {
      default: '',
      type: Text
    },
    fundraiserLink: {
      default: '',
      type: Text
    },
    fundraiserImage: {
      default: '',
      type: Text
    }
  }
};
</script>
